import Parse from 'parse';

import { goToDashboard } from './dashboard';
import { actionWithLoader, getPhotoAppURL, onEnter, push, showParseObj } from './utils';

//--------------------------------------------------------//
//------------------ loading order ---------------------//
//--------------------------------------------------------//

/**
 * load all orders
 * @returns {Function}
 */
export const loadOrdersThunk = ({ limit }) => {
  return async (dispatch) => {
    const orders = await Parse.Cloud.run('getOrders', { limit: limit || 1000 });

    if (orders) {
      dispatch({
        type: 'ORDERS_LOADED',
        orders,
      });
    }
    return orders;
  };
};

export const countOrders = async () => {
  const orders = await Parse.Cloud.run('getOrders');
  return orders.results.length;
};

/**
 * load orders with pagination
 * @returns {Function}
 */
export const loadOrdersWithPagination = (params) => {
  return async (dispatch) => {
    const orders = await Parse.Cloud.run('getOrders', params);

    dispatch({
      type: 'ORDERS_LOADED',
      orders: orders.results,
    });

    return {
      data: orders.results,
      totalCount: orders.count,
    };
  };
};

/**
 * send data order to  shipping
 * @param id
 * @returns {*}
 */
export function sendOrderToShipping(id) {
  return actionWithLoader(async () => {
    const data = await Parse.Cloud.run('sendOrderToShipping', { id });
    console.log('data: ', data);
  });
}

/**
 * refresh pdf
 * @param orderId
 * @returns {function(*=, *=): Promise<void>}
 */
export function refreshPdf(orderId) {
  return actionWithLoader(async () => {
    // run refresh imagePdf
    await Parse.Cloud.run('refreshPdfImage', { order: orderId });
  });
}

/**
 * onEnter orders
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export const onEnterOrders = (store) => {
  return onEnter({
    store,
    actionThunk: (params) => {
      return async (dispatch, getState) => {
        const orders = await loadOrdersThunk({ limit: 500 })(dispatch, getState);
        if (!orders) {
          goToDashboard();
        }
      };
    },
  });
};

/**
 * send order to Switch
 * @param id
 * @returns {Object}
 */
export const sendOrderToSwitch = (id) => {
  return actionWithLoader(async () => {
    const data = await Parse.Cloud.run('sendOrderToSwitch', { id });
    console.log('data: ', data);
  });
};

export const updateOrdersAssets = () => {
  return actionWithLoader(async (dispatch, getState) => {
    const data = await Parse.Cloud.run('updateOrdersAssets');
    dispatch({
      type: 'ORDERS_ERROR',
      orders: data,
    });
  });
};

//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//
/**
 * show order
 * @param orderId
 * @param fromNewTab
 */
export function showOrder(orderId, fromNewTab = false) {
  if (fromNewTab) {
    const url = getPhotoAppURL() + '/order-' + orderId;
    window.open(url);
    return;
  }
  return showParseObj('order', orderId);
}

export function showOrders() {
  return push('/orders');
}

export function showUpdateOrderAssets() {
  return push('/updateOrderAssets');
}
