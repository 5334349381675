import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import CustomCard from '../../components/CustomCard';
import ModalDialog from '../../components/ModalDialog';
import AddIconButton from '../../components/buttons/AddIconButton';

import TemplateForm, { ToTemplateProperties } from './TemplateForm';
import TemplatesTable from './TemplatesTable';

import { createTemplate } from '../../actions/templates';
import { getTemplates } from '../../reducers/templates';
import { getProducts } from '../../reducers/products';
import { PRODUCT_BASE_ID } from '../../constant';

const Templates = () => {
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const products = useSelector(getProducts);
  const dispatch = useDispatch();

  const templates = useSelector(getTemplates) || [];
  console.log('templates: ', templates);

  const _openDialog = () => setOpen(true);
  const _closeDialog = () => setOpen(false);

  // save change
  const _createTemplate = async (values) => {
    const newValues = ToTemplateProperties(values);
    await dispatch(createTemplate(newValues));
    _closeDialog();
    setReload(!!!reload);
  };

  // form submit
  const _submit = async () => {
    dispatch(submit('templateForm'));
  };

  const initialValues = useMemo(() => {
    const initialValues = {
      backgroundColor: '#ffffff',
      transparent: true,
    };
    const base = products?.results.find((product) => product.id === PRODUCT_BASE_ID);
    if (base) {
      initialValues.dimension = {
        value: {
          totalWidth: base.attributes.width,
          totalHeight: base.attributes.height,
        },
        label: `${base.attributes.width}x${base.attributes.height}`,
      };
      initialValues.products = [
        {
          attributes: {
            height: base.attributes.height,
            width: base.attributes.width,
          },
          label: base.name,
          value: base.id,
        },
      ];
    }
    return initialValues;
  }, [products]);

  return (
    <>
      <CustomCard
        title="Liste des templates"
        content={<TemplatesTable isReloaded={reload} onReload={setReload} rows={templates} />}
        actionHeaderButtons={<AddIconButton onAdd={_openDialog} />}
        withActionButtons={false}
        fullScreen
      />
      <ModalDialog
        title="Ajouter nouveau template"
        content={<TemplateForm onSubmit={_createTemplate} initialValues={initialValues} />}
        isVisible={open}
        onConfirm={_submit}
        onClose={_closeDialog}
      />
    </>
  );
};

Templates.propTypes = {
  templates: PropTypes.array,
};

export default Templates;
