import React, { useCallback, useRef, useState, version } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Column } from 'devextreme-react/data-grid';
import { submit } from 'redux-form';

import { formatParseObjSelectOption, getMontageImageUrl, toMoment } from '../../utils';
import TableButtonsAction from '../../components/devExpressTable/TableButtonsAction';
import CustomTableGrid from '../../components/devExpressTable/CustomTableGrid';
import ModalDialog from '../../components/ModalDialog';
import MontageForm from './MontageForm';
import { deleteMontage, getMontageValues, loadMontagesPerPage, showMontage, showMontages, updateMontage } from '../../actions/montages';
import CustomCell from '../../components/devExpressTable/CustomCell';

// const columns = ['id', 'template', 'updatedAt', 'data'];

const columns = [
  {
    dataField: 'imageUrl',
    caption: 'Image',
    cellRender: ({ data }) => <img src={getMontageImageUrl(data)} alt="product" style={{ width: 50, height: 50, objectFit: 'contain' }} />
  },
  {
    dataField: 'id',
    caption: 'ID',
    cellRender: ({ data }) => <CustomCell value={data.id} />
  },
  {
    dataField: 'template',
    caption: 'Template',
    cellRender: ({ data }) => <CustomCell value={data.template} />
  },
  {
    dataField: 'updatedAt',
    dataType: 'date',
    caption: 'Date Modification',
    defaultSortOrder: 'desc',
    cellRender: ({ data }) => <CustomCell value={toMoment(new Date(data.updatedAt)).format('YYYY-MM-DD HH:mm')} />
  },
  {
    dataField: 'state',
    caption: 'Etat',
    cellRender: ({ data }) => <CustomCell value={data.state ? 'panier' : 'En cours'} />
  },
]

const MontagesTable = ({ isReloaded, onReload }) => {


  // state
  const [selectedMontage, setSelectedMontage] = useState(null);
  const refRowsData = useRef([]);

  // dispatch
  const dispatch = useDispatch();

  // get initial values
  const getInitialValues = useCallback(() => {
    if (!selectedMontage) return;
    const montageValues = getMontageValues(selectedMontage);
    return {
      ...montageValues,
      template: formatParseObjSelectOption(montageValues.template),
    };
  }, [selectedMontage]);

  // row click
  const onRowClick = ({ data }) => {
    if (!data.data) return;

    const montageId = data.data.id;
    showMontage(montageId);
  }

  // open dialog
  const _openEditDialog = (selectedData) => {
    console.log('selectedData', selectedData);
    if (selectedData) {
      setSelectedMontage(selectedData);
    }
  }

  // close dialog
  const handleCloseDialog = () => {
    setSelectedMontage(null);
  }

  // save change
  const _save = async (values) => {
    dispatch(updateMontage(selectedMontage, values)).then(() => {
      onReload(!!!isReloaded);
    });
    handleCloseDialog();
    showMontages();
  };

  // delete a montage
  const _handleDelete = (montageId) => {
    const deletedMontage = refRowsData.current.find((row) => row.id === montageId);
    dispatch(deleteMontage(deletedMontage.montage)).then(() => {
      onReload(!!!isReloaded);
    });
  }

  // submit change
  const _submit = () => {
    dispatch(submit('montageForm'))
  };

  const loadData = async (loadOptions) => {
    let searchValue;

    if (loadOptions.filter) {
      searchValue = loadOptions.filter[0].filterValue
    }

    const dataRows = await dispatch(loadMontagesPerPage(loadOptions.take, loadOptions.skip, searchValue));
    // console.log('dataRows', dataRows);

    const data = dataRows.data.map((montage) => ({
      ...montage.toJSON(),
      id: montage.id,
      template: montage.get('template') ? montage.get('template').get('name') : 'No Template',
      updatedAt: montage.updatedAt,
      montage: montage,
      state: montage.get('isCompleted'),
      imageUrl: montage.get('image') ?? montage.get('imageUrl')
    }));
    // console.log('data', data);
    refRowsData.current = data;

    return {
      data: data,
      totalCount: dataRows.totalCount
    }
  }


  return (
    <>
      {/* ----------------- Table ----------------- */}
      <CustomTableGrid
        actionColumnWidth={120}
        onRowClick={onRowClick}
        reloadDataPagination={(loadOptions) => loadData(loadOptions)}
        reloaded={isReloaded}
        actionRender={(value) => (
          <TableButtonsAction
            // onEdit={() => _openEditDialog(value)}
            onDelete={() => _handleDelete(value?.data?.id)}
            openDialog={!!refRowsData.current.find(montage => montage?.id === selectedMontage?.id)}
            label={value?.data?.id}
          />
        )}
      >
        {columns.map((col, index) => (
          <Column key={index} {...col} />
        ))}
      </CustomTableGrid>

      {/* ----------------- Montage Edit Dialog ----------------- */}
      {selectedMontage && <ModalDialog
        title={`Modifier ${selectedMontage?.id}`}
        content={<MontageForm onSubmit={_save} initialValues={getInitialValues()} />}
        isVisible={!!refRowsData.current.find(montage => montage.id === selectedMontage?.id)}
        onConfirm={_submit}
        onClose={handleCloseDialog}
        labelConfirm="Enregistrer"
      />}
    </>
  )
}

MontagesTable.propTypes = {
  rows: PropTypes.array
};
export default MontagesTable;