import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';

import DropFileUploader from '../../components/form/DropFileUploader';
import FormField from '../../components/form/FormField';
import FormSection from '../../components/form/FormSection';
import ReduxFormReactSelect from '../../components/reduxFormMUIWrapper/ReduxFormReactSelect';

import { loadProductsThunk } from '../../actions/products';

import { getTemplateValues } from '../../actions/templates';
import { getProducts } from '../../reducers/products';
import { PRODUCT_BASE_ID } from '../../constant';

const useStyles = makeStyles({
  root: {
    composes: 'flexColumn stretch',
  },
  section: {
    width: '100%',
  },
  row: {
    composes: 'flexRow spaceAround',
    width: '100%',
  },
  fieldArrayContainer: {},
  fieldArrayHeader: {
    display: 'flex',
  },
  containerColor: {
    position: 'absolute',
    zIndex: 100,
    bottom: 0,
  },
  inputColor: {
    marginTop: 8,
  },
});

const createDimensionOption = (dimension) => {
  return {
    label: `${dimension.totalWidth}x${dimension.totalHeight}`,
    value: dimension,
  };
};

const createDimensionsOptions = (dimensions) => {
  return (dimensions ?? []).map(createDimensionOption);
};

const validate = (values) => {
  const errors = {};
  if (!values.dimension) {
    errors.dimension = 'required';
  }
  if (!values.name) {
    errors.name = 'required';
  }
  return errors;
};

const warn = (values) => {
  const warnings = {};
  if (values.products && values.dimension) {
    values.products.forEach((product) => {
      if (product.attributes.width !== values.dimension.value.totalWidth || product.attributes.height !== values.dimension.value.totalHeight) {
        warnings.products = 'Attention, les dimensions des produits ne correspondent pas à celles du template !';
      }
    });
  }

  return warnings;
};

const TemplateForm = (props) => {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const products = useSelector(getProducts);
  const classes = useStyles(props);

  // form values that must trigger a render
  const selector = formValueSelector('templateForm');
  const dimension = useSelector((state) => selector(state, 'dimension'));
  // const transparent = useSelector((state) => selector(state, 'transparent'));

  const filterProducts = (results) => {
    if (!results || !dimension?.value) return [];
    const filteredProducts = [];
    const { totalWidth, totalHeight } = dimension.value;
    results.forEach((result) => {
      const { totalWidth: resultWidth, totalHeight: resultHeight } = result.attributes;
      if (resultWidth === totalWidth && resultHeight === totalHeight) {
        filteredProducts.push(result);
      }
    });

    return filteredProducts;
  };

  const filteredProducts = useMemo(() => {
    const filteredProducts = filterProducts(products?.results);
    return filteredProducts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension]);

  useEffect(() => {
    batch(() => {
      dispatch(loadProductsThunk({ limit: 1000, fields: 'name, attributes' })); // max value authorized by Swell
    });
  }, [dispatch]);

  const baseExist = useMemo(() => {
    return products?.results?.some((product) => product.id === PRODUCT_BASE_ID);
  }, [products]);

  return (
    <form
      className={classes.root}
      onSubmit={handleSubmit}
    >
      <FormSection title="Généralité" fullWidth className={classes.section}>
        <FormField label="Nom" name="name" fullWidth />
        <FormField
          label="Dimensions"
          name="dimension"
          fullWidth
          component={ReduxFormReactSelect}
          options={createDimensionsOptions(products?.dimensions)}
          readOnly={!!baseExist}
          isOptionSelected={(option, selectValue) => {
            // selected value null in created form
            if (selectValue.length === 0) return false;
            const dim = selectValue[0].value;
            const optionValue = option.value;
            return dim.totalWidth === optionValue.totalWidth && dim.totalHeight === optionValue.totalHeight;
          }}
        />
        <FormField label="Produits" name="products" fullWidth isMulti component={ReduxFormReactSelect} options={toProductOptions(filteredProducts)} />

      </FormSection>
      <FormSection title="Image" fullWidth className={classes.section}>
        <FormField name="image" component={DropFileUploader} fullWidth />
      </FormSection>
      <input type="submit" />
    </form>
  );
};

TemplateForm.propTypes = {
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};

export default reduxForm({
  form: 'templateForm',
  asyncValidating: true,
  validate,
  warn,
})(TemplateForm);

//-------------------------------------------------------------------//
//--------------------- From and to template ------------------------//
//-------------------------------------------------------------------//
export function valuesFromTemplate(template) {
  const values = getTemplateValues(template);
  const transparent = values.backgroundColor === 'transparent';

  const products = toProductOptions(values.products);
  products.forEach((product) => {
    if (product?.attributes?.width === 0 && product?.attributes?.height === 0) {
      const newAttributes = {
        width: values.width,
        height: values.height,
      };
      product.attributes = { ...newAttributes };
    }
  });

  return {
    ...values,
    transparent,
    backgroundColor: transparent ? '#ffffff' : values.backgroundColor,
    dimension: createDimensionOption({
      totalWidth: values.width,
      totalHeight: values.height,
    }),
    /** format the template products into the react select options */
    products,
    // themes: formatParseObjSelectOptions(selectedTemplate.themes),
  };
}

export function ToTemplateProperties(values) {
  const {
    dimension: {
      value: { totalWidth, totalHeight },
    },
    products,
    transparent,
  } = values;

  const newValues = {
    ...values,
    products: fromProductOptions(products),
    dpi: 150,
  };

  //---- dimension ----//
  // Note that dimension doesn't contain lostBorder.
  // It's normal, we don't store lostBorder in the template. It's only a rectangle with layers in it.
  newValues.width = totalWidth;
  newValues.height = totalHeight;
  delete newValues.dimension;

  //---- transparent background ----//
  if (transparent) {
    newValues.backgroundColor = 'transparent';
  }
  delete newValues.transparent;

  return newValues;
}

//---------------------------------------//
//------------- Products ----------------//
//---------------------------------------//
/**
 * @param products the products as stored in the db as Template.products
 * @returns {Array}
 */
const toProductOptions = (products) => {
  if (!Array.isArray(products)) {
    return [];
  }

  return products.map((item) => {
    return {
      label: item.name,
      value: item.id,
      attributes: {
        width: item?.attributes?.totalWidth || 0,
        height: item?.attributes?.totalHeight || 0,
      },
    };
  });
};

/**
 * format products values before save
 * @param products
 * @returns {Array}
 */
export const fromProductOptions = (products) => {
  if (!Array.isArray(products)) {
    return [];
  }
  return products.map((product) => ({
    id: product.value,
    name: product.label,
  }));
};
