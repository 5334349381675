import React from 'react';

import CustomCard from '../../components/CustomCard';
import OrdersTable from './OrdersTable';

const Orders = () => {

	return (
		<CustomCard
			title='Liste des commandes'
			content={<OrdersTable />}
			withActionButtons={false}
			fullScreen
		/>
	)
}

export default Orders;