import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import MenuItemIcon from './MenuItemIcon';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: '#e0f0fb',
      color: '#2c7be5',
      '& .MuiSvgIcon-root-63': {
        color: '#2c7be5',
      },
    },
  },
  selected: {
    backgroundColor: '#e0f0fb',
    fontWeight: 'bold',
    color: '#2c7be5',
    '& .MuiSvgIcon-root-63': {
      color: '#2c7be5',
    },
  },
});

const RootMenuItem = (props) => {
  // props
  const { label, name, selected, onClick } = props;

  // styles
  const classes = useStyles();

  const _onClick = () => {
    onClick && onClick(name);
  };

  return (
    <ListItem button key={label} onClick={_onClick} className={selected ? classes.selected : classes.root}>
      <ListItemIcon>
        <MenuItemIcon name={name} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};

RootMenuItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
  selected: PropTypes.bool,
};

export default RootMenuItem;
