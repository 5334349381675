import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Column } from 'devextreme-react/data-grid';
import { submit } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';

import { getGCPUrl, toMoment } from '../../utils';
import TableButtonsAction from '../../components/devExpressTable/TableButtonsAction';
import ModalDialog from '../../components/ModalDialog';
import ImagePreview from '../../components/ImagePreview';

import TemplateForm, { ToTemplateProperties, valuesFromTemplate } from './TemplateForm';
import { deleteTemplate, showTemplate, showTemplates, updateTemplate } from '../../actions/templates';
import CustomCell from '../../components/devExpressTable/CustomCell';
import CustomImageCell from '../../components/devExpressTable/CustomImageCell';

import { loadTemplatesPerPage } from '../../actions/templates';
import CustomTableGrid from '../../components/devExpressTable/CustomTableGrid';
import Template from './Template';

// table columns
const columns = ['image', 'name', 'updatedAt', 'dimensionPx', 'dimensionCm', 'products', 'themes', 'data'];

const useStyles = makeStyles({
  img: {
    width: '100%',
  },
  templateImage: {
    width: '100%',
    height: '100%',
    '& .konvajs-content': {
      width: '100% !important',
      height: '100% !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& canvas': {
      width: 'auto !important',
      height: '100% !important',
    }
  }
});

const TemplatesTable = (props) => {
  const { rows, isReloaded, onReload } = props;
  const classes = useStyles(props);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const refRowsData = useRef([]);
  const dispatch = useDispatch();

  // the form component doesn't accept a function as initialValues,
  // so we have to use useMemo
  const formInitialValues = useMemo(() => {
    if (!selectedTemplate) {
      return {};
    }
    const values = valuesFromTemplate(selectedTemplate.template);
    return values;
  }, [selectedTemplate]);

  if (!rows) return null;

  // delete template
  const _handleDelete = (selectedData) => {
    if (!selectedData) return;

    const templateId = selectedData.data.id;
    const themesByTemplate = selectedData.themes;
    const deletedTemplate = rows.find((row) => row.id === templateId);
    dispatch(deleteTemplate(deletedTemplate, themesByTemplate)).then(() => {
      onReload(!!!isReloaded);
    });
  };

  const handlePreview = (templateId) => dispatch(showTemplate(templateId));

  // row click
  const onRowClick = ({ data }) => {
    if (!data.data) return;

    const templateId = data.data.id;
    handlePreview(templateId);
  };

  // open dialog
  const _openEditDialog = (selectedData) => {
    if (selectedData?.data) {
      setSelectedTemplate({
        template: selectedData.data,
        themes: selectedData.themes,
      });
    }
  };

  // close dialog
  const handleCloseDialog = () => {
    setSelectedTemplate(null);
  };

  const _save = async (values) => {
    const newValues = ToTemplateProperties(values);
    await dispatch(updateTemplate(selectedTemplate, newValues, false));
    handleCloseDialog();
    showTemplates();
  };

  // column with image
  const renderImage = (value) => {
    if (!value.value || !value.data.data.id) {
      return <CustomImageCell>
        <Template
          template={value.data.data}
          className={classes.templateImage}
        />
      </CustomImageCell>
    }

    const path = `${getGCPUrl()}${value.data.data.id.toLowerCase()}/${value.value}`;
    return (
      <CustomImageCell>
        <ImagePreview className={classes.img} src={path} placeholder={`${path}?cache=true`} />
      </CustomImageCell>
    );
  };

  // submit change
  const _submit = () => {
    onReload(!!!isReloaded);
    dispatch(submit('templateForm'));
  };

  // Customized source data
  const loadTemplatesData = async (loadOptions) => {
    let searchValue;
    if (loadOptions.filter) {
      searchValue = loadOptions.filter[0].filterValue;
    }
    const dataRows = await dispatch(loadTemplatesPerPage(loadOptions.take, loadOptions.skip, searchValue));
    const data = [];
    for (const template of dataRows.data) {
      data.push({
        [columns[0]]: template.get('imageId'),
        [columns[1]]: template.get('name'),
        [columns[2]]: template.updatedAt,
        [columns[3]]: `${template.getWidthInPx()}x${template.getHeightInPx()}`,
        [columns[4]]: `${template.get('width')}x${template.get('height')}`,
        [columns[5]]: template.get('products'),
        [columns[6]]: null,
        [columns[7]]: template,
      });
    }
    refRowsData.current = data;

    return {
      data: data,
      totalCount: dataRows.totalCount,
    };
  };

  return (
    <>
      {/* ----------------- Table ----------------- */}
      <CustomTableGrid
        reloadDataPagination={(loadOptions) => loadTemplatesData(loadOptions)}
        onRowClick={onRowClick}
        actionColumnWidth={120}
        reloaded={isReloaded}
        actionRender={(value) => (
          <TableButtonsAction
            onEdit={() => _openEditDialog(value.data)}
            onDelete={() => _handleDelete(value.data)}
            openDialog={!!refRowsData.current.find((template) => template.id === selectedTemplate?.template.id)}
            label={value.data.name}
          />
        )}
      >
        <Column dataField={columns[0]} caption="Image" cellRender={renderImage} width={100} />
        <Column dataField={columns[1]} caption="Nom" cellRender={({ data }) => <CustomCell value={data.name} />} />
        <Column
          dataField={columns[2]}
          dataType="date"
          caption="Date Modification"
          defaultSortOrder="desc"
          cellRender={({ data }) => <CustomCell value={toMoment(new Date(data.updatedAt)).format('YYYY-MM-DD')} />}
        />
        <Column
          dataField={columns[3]}
          caption="Dimension en px"
          cellRender={({ data, column }) => <CustomCell value={data.dimensionPx} alignment={column.alignment} />}
        />
        <Column
          dataField={columns[4]}
          caption="Dimension en cm"
          cellRender={({ data, column }) => <CustomCell value={data.dimensionCm} alignment={column.alignment} />}
        />
        <Column
          dataField={columns[5]}
          caption="Produits"
          cellRender={({ data, column }) => {
            return (data.products ?? []).map((product, index) => <CustomCell key={index} value={product.name} alignment={column.alignment} />);
          }}
        />
      </CustomTableGrid>

      {/* ----------------- Template Edit Dialog ----------------- */}
      <ModalDialog
        title={`Modifier ${selectedTemplate?.template.id}`}
        content={<TemplateForm onSubmit={_save} initialValues={formInitialValues} />}
        isVisible={!!rows.find((template) => template.id === selectedTemplate?.template.id)}
        onConfirm={_submit}
        onClose={handleCloseDialog}
        labelConfirm="Enregistrer"
      />
    </>
  );
};

TemplatesTable.propTypes = {
  template: PropTypes.object,
};

export default TemplatesTable;
