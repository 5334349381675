import { Button, CardActions, makeStyles } from '@material-ui/core';
import { arrayMoveMutable } from 'array-move';
import classNames from 'classnames';
import { nth, pullAt } from 'lodash';
import Parse from 'parse';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKey, useUpdate } from 'react-use';
import { copyTemplateToProd, regenerateImages, saveTemplateEditor, setImageParse, showTemplates, updateTemplate } from '../../actions/templates';
import { getTemplate } from '../../reducers/templates';
import { mapLayersRecursively } from '../../utils';
import TemplateDetails from './TemplateDetails';
import ZoomButtons from './ZoomButtons';
import Editor from './editor/Editor';
import PropertiesForm from './layers/LayerForm';
import LayersList from './layers/LayersList';
import ModalAddLayer from './layers/ModalAddLayer';

const useStyles = makeStyles((theme) => ({
  root: {
    composes: 'flexColumn stretch',
    width: '100%',
    minHeight: 'calc(98vh - 40px)',
    padding: '0px 20px',
  },
  container: {
    composes: 'flexRow ',
    height: 'calc(98vh - 55px)',
    backgroundColor: theme.background.blue[568],
    border: theme.border.grey[645],
  },
  leftContainer: {
    composes: 'flexColumn stretch',
    borderWidth: 2,
    width: 300,
    height: '100%',
    boxShadow: '1px 0px 3px 1px rgba(0, 0, 0, 0.1)',
  },
  rightContainer: {
    composes: 'flex1 flexColumn',
    height: '100%',
    position: 'relative',
  },
  actions: {
    borderTop: theme.border.grey[620],
    backgroundColor: theme.background.grey[505],
    width: '100%',
    padding: '4px 10px',
    justifyContent: 'flex-end',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    height: 45,
  },
  regenerateButton: {
    marginLeft: 10,
    fontFamily: 'Montserrat',
    fontWeight: 600,
  },
  layerProperties: {
    composes: 'flexColumn stretch',
    overflow: 'hidden',
    padding: '10px 0 10px 10px',
  },
  layerPropertiesContent: {
    flex: 1,
    overflow: 'auto',
    '& .form-container': {
      width: 'initial',
    },
  },
  btn: {
    minWidth: 180,
  },
  okBtn: {
    composes: '$btn',
    marginLeft: 15,
  },
}));

const TemplateEditor = (props) => {
  const update = useUpdate();
  const dispatch = useDispatch();

  const classes = useStyles(props);
  const template = useSelector(getTemplate);

  const [hasChanged, setHasChanged] = useState(false);
  const [layers, setLayers] = useState([]);
  const [isOpenCreationModal, setIsOpenCreationModal] = useState(false);
  const [idMaskActive, setIdMaskActive] = useState('');
  const [selectedLayer, setSelectedLayer] = useState(null);
  /**
   *  0 : full size
   *  1 : fit screen
   */
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    const originalLayers = template.get('layers') || [];
    setLayers([...originalLayers]);
  }, [template]);

  const _saveTemplateProperties = async (values) => {
    await dispatch(updateTemplate({ template }, values, false));
    setHasChanged(true);
    update();
  };

  const _showCreationModal = (idMasque = '') => {
    setIsOpenCreationModal(true);
    setIdMaskActive(idMasque);
  };

  const _closeCreationModal = () => {
    setIsOpenCreationModal(false);
  };

  const _regenerateImages = () => {
    dispatch(regenerateImages(template.id));
  };

  const _copyTemplateToProd = () => {
    dispatch(copyTemplateToProd(template.id));
  };

  const _cancel = () => {
    showTemplates();
  };

  const _saveLayerProperties = async (values) => {
    console.log('values', values);
    for (const [key, value] of Object.entries(values)) {
      selectedLayer[key] = value;
    }
  };

  const _saveTemplate = async () => {
    dispatch(saveTemplateEditor(template, layers));
    setHasChanged(false);
  };

  const onChangeLayerProperties = async (newLayer) => {
    if (newLayer.imageFile) {
      const fileType = newLayer.imageFile instanceof Parse.File;

      if (!fileType) {
        newLayer.imageTemp = newLayer.imageFile;
        await setImageParse(newLayer);
      }
    }
    setLayers(mapLayersRecursively(layers, newLayer.id, () => newLayer));
    setSelectedLayer(newLayer);
    setHasChanged(true);
  };

  const onEditorLayerChange = (layer) => {
    onChangeLayerProperties(layer);
    // onChange(newLayers);
  };

  const onAddLayer = (newLayers) => {
    setLayers(newLayers);
    _closeCreationModal();
    setHasChanged(true);
    setIdMaskActive('');
  };

  const layersOrderChanged = (sourceIndex, destinationIndex) => {
    const orderedLayers = [...layers];
    arrayMoveMutable(orderedLayers, sourceIndex, destinationIndex);
    setLayers([...orderedLayers]);
    setHasChanged(true);
    update();
  };

  const handleLayerSelected = (item) => {
    setSelectedLayer(item);
  };

  const deleteLayer = (layerIndex) => {
    // remove selectedLayer after remove
    const item = nth(layers, layerIndex);
    if (item === selectedLayer) {
      setSelectedLayer(null);
    }
    pullAt(layers, layerIndex);
    setLayers([...layers]);
    setHasChanged(true);
    update();
  };

  const deselectLayer = () => {
    setSelectedLayer(null);
  };

  useKey('Escape', () => deselectLayer());

  const layersOrdersMaskChanged = (maskId, sourceIndex, destinationIndex) => {
    const newLayers = mapLayersRecursively(layers, maskId, (maskItem) => {
      arrayMoveMutable(maskItem.layers, sourceIndex, destinationIndex);
      return maskItem;
    });
    setLayers(newLayers);
    setHasChanged(true);
    update();
  };

  const handleDeleteMaskChildreen = (maskId, removeIndex) => {
    const newLayers = mapLayersRecursively(layers, maskId, (maskItem) => {
      pullAt(maskItem.layers, removeIndex);
      return maskItem;
    });
    setLayers(newLayers);
    setHasChanged(true);
    update();
  };

  const handleToggleVisibilityLayer = (layer, value) => {
    layer.hide = value;
    setHasChanged(true);
    update();
  };

  const handleChangeUsedForPrint = (selectedLayer, value) => {
    const prevLayers = [...layers];

    const newLayers = prevLayers.map((layer) => {
      const newLayer = { ...layer };
      if (layer.id === selectedLayer.id) {
        newLayer.usedForPrint = value;
      }

      return newLayer;
    });

    setHasChanged(true);
    setLayers([...newLayers]);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <TemplateDetails template={template} onSave={_saveTemplateProperties} />
          <LayersList
            layers={layers}
            onOpenAddDialog={_showCreationModal}
            onOrderChange={layersOrderChanged}
            selectedLayer={selectedLayer}
            onSelection={handleLayerSelected}
            onDelete={deleteLayer}
            onDeselection={deselectLayer}
            onOrderItemsMask={layersOrdersMaskChanged}
            onRemoveItemMask={handleDeleteMaskChildreen}
            toggleVisibility={handleToggleVisibilityLayer}
            onChangeUsedForPrint={handleChangeUsedForPrint}
          />
          {selectedLayer && (
            <div className={classes.layerProperties}>
              <div className={classes.layerPropertiesTitle}>Propriétés du calque sélectionné</div>
              <div className={classes.layerPropertiesContent}>
                <PropertiesForm
                  onSubmit={_saveLayerProperties}
                  onChange={onChangeLayerProperties}
                  initialValues={{
                    ...(selectedLayer ?? {}),
                    ...(selectedLayer?.usedForPrint == null ? { usedForPrint: true } : {}),
                  }}
                />
              </div>
            </div>
          )}
          <div className="flex1"></div>
          <CardActions classes={{ root: classNames(classes.actions) }}>
            <div className={classes.buttons}>
              <Button color="secondary" onClick={_cancel}>
                Annuler
              </Button>

              <Button color="primary" onClick={_saveTemplate} classes={{ root: classes.okBtn }} disabled={!hasChanged}>
                Enregistrer
              </Button>
            </div>
          </CardActions>
        </div>
        <div className={classes.rightContainer}>
          <Editor
            onClickOutside={deselectLayer}
            onClickLayer={handleLayerSelected}
            layers={layers}
            template={template}
            selectedLayer={selectedLayer}
            onChange={onEditorLayerChange}
            zoom={zoom}
          />
          <CardActions classes={{ root: classNames(classes.actions) }}>
            <div style={{ flex: 1 }} />
            <ZoomButtons value={zoom} onChange={setZoom} />
            {/* <div>
              <Button color="primary" variant="contained" className={classes.regenerateButton} onClick={_regenerateImages}>
                Regénérer les images
              </Button>
              <Button color="primary" variant="contained" className={classes.regenerateButton} onClick={_copyTemplateToProd}>
                Copier vers Prod
              </Button>
            </div> */}
          </CardActions>
        </div>
      </div>

      <ModalAddLayer
        isOpen={isOpenCreationModal}
        onClose={_closeCreationModal}
        layers={layers}
        idMaskActive={idMaskActive}
        template={template}
        onAddLayer={onAddLayer}
      />
    </div>
  );
};

TemplateEditor.propTypes = {
  template: PropTypes.object,
};
export default TemplateEditor;
