import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { createBackground } from '../../actions/backgrounds';
import AddIconButton from '../../components/buttons/AddIconButton';
import CustomCard from '../../components/CustomCard';
import ModalDialog from '../../components/ModalDialog';
import { validateBackground } from '../../validation';
import BackgroundForm from './BackgroundForm';
import { getBackgroundGroups, getBackgroundGroupsByParent, reorderBackgroundGroups } from '../../actions/backgroundGroups';
import { deleteBackground, editBackground, getBackgroundsPerPage, reorderBackgrounds, updateBackground } from '../../actions/backgrounds';
import OrderableTable from '../../components/OrderableTable';

const Backgrounds = () => {
  const dispatch = useDispatch();
  const backgrounds = useSelector((state) => state.backgrounds?.backgrounds);
  const background = useSelector((state) => state.backgrounds?.background);
  const backgroundGroups = useSelector((state) => state.backgroundGroups.backgroundGroups);
  const backgroundSubgroups = useSelector((state) => state.backgroundGroups.backgroundSubgroups);

  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const handleCreate = async (values) => {
    validateBackground(values);
    await dispatch(createBackground(values));
    closeDialog();
  };

  const handleSubmit = () => dispatch(submit('backgroundForm'));

  return (
    <>
      <CustomCard
        title="Liste des fonds"
        actionHeaderButtons={<AddIconButton onAdd={openDialog} />}
        content={
          <OrderableTable
            items={backgrounds}
            selected={background}
            groups={backgroundGroups}
            subGroups={backgroundSubgroups}
            getGroups={getBackgroundGroups}
            getGroupsByParent={getBackgroundGroupsByParent}
            reorderGroups={reorderBackgroundGroups}
            deleteItem={deleteBackground}
            edit={editBackground}
            getPerPage={getBackgroundsPerPage}
            reorder={reorderBackgrounds}
            update={updateBackground}
            validate={validateBackground}
            imageFolder="backgrounds"
          />
        } //@TODO use reusable component same as Overlay
        withActionButtons={false}
        fullScreen
      />
      <ModalDialog
        title="Ajout nouveau fond"
        content={<BackgroundForm onSubmit={handleCreate} />}
        isVisible={open}
        onConfirm={handleSubmit}
        onClose={closeDialog}
      />
    </>
  );
};

Backgrounds.propTypes = {
  backgrounds: PropTypes.array,
};

export default Backgrounds;
