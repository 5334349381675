import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { createOverlay } from '../../actions/overlays';
import AddIconButton from '../../components/buttons/AddIconButton';
import CustomCard from '../../components/CustomCard';
import ModalDialog from '../../components/ModalDialog';
import { validateOverlay } from '../../validation';
import OverlayForm from './OverlayForm';
import OrderableTable from '../../components/OrderableTable';
import { getOverlayGroups, getOverlayGroupsByParent, reorderOverlayGroups } from '../../actions/overlayGroups';
import { deleteOverlay, editOverlay, getOverlaysPerPage, reorderOverlays, updateOverlay } from '../../actions/overlays';

const Overlays = () => {
  const overlays = useSelector((state) => state.overlays?.overlays);
  const overlay = useSelector((state) => state.overlays?.overlay);
  const overlayGroups = useSelector((state) => state.overlayGroups?.overlayGroups);
  const overlaySubgroups = useSelector((state) => state.overlayGroups?.overlaySubgroups);

  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleCreate = async (values) => {
    validateOverlay(values);
    await dispatch(createOverlay(values));
    handleCloseDialog();
  };

  const handleSubmit = () => dispatch(submit('overlayForm'));

  return (
    <>
      <CustomCard
        title="Liste des overlays"
        actionHeaderButtons={<AddIconButton onAdd={handleOpenDialog} />}
        content={<OrderableTable
          items={overlays}
          selected={overlay}
          groups={overlayGroups}
          subGroups={overlaySubgroups}
          getGroups={getOverlayGroups}
          getGroupsByParent={getOverlayGroupsByParent}
          reorderGroups={reorderOverlayGroups}
          deleteItem={deleteOverlay}
          edit={editOverlay}
          getPerPage={getOverlaysPerPage}
          reorder={reorderOverlays}
          update={updateOverlay}
          validate={validateOverlay}
        />}
        withActionButtons={false}
        fullScreen
      />
      <ModalDialog
        title="Ajout nouveau overlay"
        content={<OverlayForm onSubmit={handleCreate} />}
        isVisible={openDialog}
        onConfirm={handleSubmit}
        onClose={handleCloseDialog}
      />
    </>
  );
};

Overlays.propTypes = {
  overlays: PropTypes.array,
};

export default Overlays;
