import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { goToDashboard } from '../actions/dashboard';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '45vw',
  },
  header: {
    width: '100%',
    padding: '10px 20px',
    borderBottom: theme.border.grey[520],
  },
  content: {
    composes: 'stretchSelf',
  },
  actions: {
    borderTop: theme.border.grey[620],
    backgroundColor: theme.background.grey[505],
    width: '100%',
    padding: '4px 10px',
    justifyContent: (props) => (props.actions ? 'flex-start' : 'flex-end'),
  },
  buttons: {},
  okBtn: {
    marginLeft: 10,
  },
  fullScreenContainer: {
    composes: 'flexColumn self flex1 stretchSelf',
    height: 'calc(100vh - 50px)',
  },
  fullScreenContent: {
    composes: 'flex1 overflowAuto',
    maxHeight: 'unset !important',
  },
  contentChild: {
    '&>div': {
      height: '85vh',
    },
  },
}));

const CustomCard = (props) => {
  // props
  const {
    elevation = 3,
    rootClassName,
    fullScreen = false,
    title,
    titleClassName,
    content,
    contentClassName,
    withActionButtons = true,
    actions,
    actionClassName,
    okAction,
    okLabel = 'Valider',
    cancelAction,
    cancelLabel = 'Annuler',
    actionHeaderButtons,
  } = props;

  // styles
  const classes = useStyles(props);

  const actionItems = actions ? (
    actions
  ) : (
    <div className={classes.buttons}>
      <Button color="secondary" onClick={cancelAction || goToDashboard}>
        {cancelLabel}
      </Button>

      {okAction && (
        <Button color="primary" onClick={okAction} classes={{ root: classes.okBtn }}>
          {okLabel}
        </Button>
      )}
    </div>
  );

  return (
    <Card
      elevation={elevation}
      classes={{
        root: classNames(classes.root, rootClassName, {
          [classes.fullScreenContainer]: fullScreen,
        }),
      }}
    >
      {/*---- header ----*/}
      {title && (
        <CardHeader
          title={typeof title === 'string' ? <Typography variant="h5">{title}</Typography> : title}
          classes={{ root: classNames(classes.header, titleClassName) }}
          action={actionHeaderButtons}
        />
      )}

      {/*---- content ----*/}
      <CardContent
        classes={{
          root: classNames(classes.content, contentClassName, {
            [classes.fullScreenContent]: fullScreen,
          }),
        }}
        className={classes.contentChild}
      >
        {content}
      </CardContent>

      {/*---- actions ----*/}
      {withActionButtons && <CardActions classes={{ root: classNames(classes.actions, actionClassName) }}>{actionItems}</CardActions>}
    </Card>
  );
};

CustomCard.propTypes = {
  elevation: PropTypes.number,
  rootClassName: PropTypes.any,
  title: PropTypes.any,
  titleClassName: PropTypes.any,
  content: PropTypes.node,
  contentClassName: PropTypes.any,
  withActionButtons: PropTypes.bool,
  actions: PropTypes.node,
  actionClassName: PropTypes.any,
  okAction: PropTypes.func,
  okLabel: PropTypes.string,
  cancelAction: PropTypes.func,
  cancelLabel: PropTypes.string,
  fullScreen: PropTypes.bool,
};

export default CustomCard;
