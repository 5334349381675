export const fonts = ['Montserrat', 'AmaticSC', 'Courgette', 'DelaGothicOne', 'Neucha', 'Parisienne', 'PlayfairDisplay'];
export const textAlignments = [
  { label: 'Center', value: 'center' },
  { label: 'Left', value: 'left' },
  { label: 'Right', value: 'right' },
];

export const GCP_PATHNAME = 'https://storage.googleapis.com/';

export const PROD_HOSTNAME = 'bo.yourz.me';
export const PREPROD_HOSTNAME = 'bo-preprod.yourz.me';
export const LOCAL_HOSTNAME = 'localhost';
export const LOCAL_ENDPOINT_HOSTNAME = '127.0.0.1';
export const CLOUD_IMAGE_URL = process.env.REACT_APP_IMAGE_SERVER_URL ?? "https://generateimage-ibswvu3lfa-od.a.run.app/";

export const PROD_URL = 'https://' + PROD_HOSTNAME;
export const PREPROD_URL = 'https://' + PREPROD_HOSTNAME;
export const LOCAL_URL = 'http://' + LOCAL_HOSTNAME;
export const LOCAL_ENDPOINT = process.env.REACT_APP_SERVER_URL ?? 'http://' + LOCAL_ENDPOINT_HOSTNAME + ':1337';
export const IMAGEKIT_URL_ENDPOINT = process.env.REACT_APP_IMAGEKIT_URL_ENDPOINT ?? 'https://ik.imagekit.io/sido337lt';
export const GCS_USERS_BUCKET_NAME = process.env.REACT_APP_GCS_USERS_BUCKET_NAME ?? 'silhouette-users';
export const PRODUCT_BASE_ID = '675191cd687ffa0012140b9a';
export const FORCE_PRODUCTION = false;
