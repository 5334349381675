import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import { validateMontage } from '../../validation';

import CustomCard from '../../components/CustomCard';
import AddIconButton from '../../components/buttons/AddIconButton';
import ModalDialog from '../../components/ModalDialog';
import MontageForm from './MontageForm';
import MontagesTable from './MontagesTable';

import { getMontages, getMontageCount } from '../../reducers/montages';
import { createMontage } from '../../actions/montages';

const Montages = () => {

	// state
	const [open, setOpen] = useState(false);
	const [reload, setReload] = useState(false);

	// dispatch
	const dispatch = useDispatch();

	// selectors
	const montages = useSelector(getMontages) || [];
	const count = useSelector(getMontageCount) || 0;

	// dialog actions
	const _openDialog = () => setOpen(true);
	const _closeDialog = () => setOpen(false);

	// save change
	const _createMontage = async (values) => {
		validateMontage(values);

		const newValues = { ...values };

		if (values?.template?.parseObj) {
			newValues.template = values.template.parseObj;
		}

		// create new montages
		await dispatch(createMontage(newValues)).then(() => {
			setReload(!!!reload);
		});

		_closeDialog();
	};

	// form submit
	const _submit = () => {
		dispatch(submit('montageForm'))
	};

	return (
		<>
			<CustomCard
				title='Liste des montages'
				content={<MontagesTable rows={montages} count={count} isReloaded={reload} onReload={setReload} />}
				withActionButtons={false}
				actionHeaderButtons={<AddIconButton onAdd={_openDialog} />}
				fullScreen
			/>
			<ModalDialog
				title="Ajouter Nouveau montage"
				content={<MontageForm onSubmit={_createMontage} />}
				isVisible={open}
				onConfirm={_submit}
				onClose={_closeDialog}
			/>
		</>
	)
}

Montages.propTypes = {
	montages: PropTypes.array
};
export default Montages;