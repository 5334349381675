import { isEqual, orderBy, uniqWith } from 'lodash';
import Parse from 'parse';

import { uid } from 'uid';
import { getProduct, getProducts } from '../reducers/products';
import { groupBy, parseSwellNumber } from '../utils';
import { goToDashboard } from './dashboard';
import { loadTemplatesThunk } from './templates';
import { actionWithLoader, getPhotoAppURL, onEnter, push, showParseObj } from './utils';

const getImage = async (inputImage) => {
  /** image url formatting */
  let image;
  if (typeof inputImage === 'string') {
    image = inputImage;
  } else {
    image = new Parse.File(uid(24), inputImage);
    await image.save();
  }
  return image;
};

const formatIconFieldsArray = async (values) => {
  const newValues = [];
  if (values) {
    for (const value of values) {
      const icon = await getImage(value.icon);
      newValues.push({ ...value, icon });
    }
  }

  return newValues;
};

//--------------------------------------------------------//
//--------------------- CRUD actions ---------------------//
//--------------------------------------------------------//
/**
 * create or update a product promo banner
 * @param values
 * @returns {*}
 */
export const addOrUpdateProductCustomFields = (values) => {
  return actionWithLoader(async (dispatch, getState) => {
    const products = getProducts(getState());

    //--------------------------------------------------------//
    //------------------ image formatting --------------------//
    //--------------------------------------------------------//
    const newValues = { ...values };

    if (values.details && values.details.length > 0) {
      const details = await formatIconFieldsArray(values.details);
      newValues.details = details;
    } else {
      delete newValues.details;
    }

    if (values.shipping && values.shipping.length > 0) {
      const shipping = await formatIconFieldsArray(values.shipping);
      newValues.shipping = shipping;
    } else {
      delete newValues.shipping;
    }

    if (values.idealFor && values.idealFor.length > 0) {
      const idealFor = await formatIconFieldsArray(values.idealFor);
      newValues.idealFor = idealFor;
    } else {
      delete newValues.idealFor;
    }

    //--------------------------------------------------------//
    //------------------- webservice call --------------------//
    //--------------------------------------------------------//
    const product = await Parse.Cloud.run('addOrUpdateProductCustomFields', {
      ...newValues,
    });

    //--------------------------------------------------------//
    //---------------- product list update -------------------//
    //--------------------------------------------------------//
    const results = [...products.results.filter((p) => p.id !== product.id), product];

    //--------------------------------------------------------//
    //---------------------- dispatch ------------------------//
    //--------------------------------------------------------//
    dispatch({
      type: 'PRODUCT_LOADED',
      product,
    });
    dispatch({
      type: 'PRODUCTS_UPDATED',
      products: {
        ...products,
        results,
      },
    });
  });
};

//--------------------------------------------------------//
//------------------ loading product ---------------------//
//--------------------------------------------------------//
/**
 * onEnter product preview or edit page
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export function onEnterProduct(store) {
  return onEnter({
    store,
    actionThunk: (params) => {
      return async (dispatch, getState) => {
        const productId = params.productId;
        const product = await loadProductThunk(productId)(dispatch, getState);
        if (!product) {
          // product not found
          goToDashboard();
        }
      };
    },
  });
}

/**
 * load product into redux
 * @param productId
 * @returns {function(*, *): Promise<*>}
 */
export function loadProductThunk(productId) {
  return async (dispatch, getState) => {
    const currentProduct = getProduct(getState());
    if (!currentProduct || currentProduct.id !== productId) {
      // loading product
      const product = await Parse.Cloud.run('getProduct', { id: productId });

      dispatch({
        type: 'PRODUCT_LOADED',
        product,
      });
      return product;
    }

    return currentProduct;
  };
}

/**
 * save templates
 * @param templates
 * @returns {function(*, *): Promise<*>}
 */
async function saveProductForTemplates(templates, productId, newProduct) {
  for (const template of templates) {
    const products = template.get('products');

    const newProducts = [];
    if (newProduct) {
      newProducts.push(newProduct);
    }
    if (products) {
      products.filter((p) => p.id !== productId).forEach((p) => newProducts.push(p));
    }

    template.set('products', newProducts);
    await template.save();
  }
}

const callGetProducts = async ({ limit, page, fields }) => {
  const products = await Parse.Cloud.run('getProducts', { limit, page, fields });

  // attributes types corrections
  if (products.results) {
    products.results.forEach((products) => {
      const attributes = products.attributes;

      if (attributes.width) {
        attributes.width = parseSwellNumber(attributes.width);
      }
      if (attributes.height) {
        attributes.height = parseSwellNumber(attributes.height);
      }

      let lostBorder = 0;
      // for lost_border, we also change to camelCase
      if (attributes.lost_border?.length > 0) {
        // it's not empty
        lostBorder = parseSwellNumber(attributes.lost_border);
        attributes.lostBorder = lostBorder;
        delete attributes.lost_border; // just not to be tempted to use it
      }

      attributes.totalWidth = attributes.width + 2 * lostBorder;
      attributes.totalHeight = attributes.height + 2 * lostBorder;
    });
  }
  return products;
};

/**
 * load all products
 * @returns {Function}
 */
export function loadProductsThunk({ limit, fields }) {
  return async (dispatch) => {
    // TODO : get a more optimized version, like getProductsDimension
    const products = await callGetProducts({ limit, fields });

    //-------- get all product sizes ----------------//
    if (products.results) {
      const dimensions = [];

      products.results.forEach((result) => {
        const { width, height, lostBorder, totalWidth, totalHeight } = result.attributes;

        if (width && height) {
          // we add all dimension-related attributes so that we can later have the choice
          const dimension = { width, height, lostBorder, totalWidth, totalHeight };
          dimensions.push(dimension);
        }
      });

      // ----------- distinct and sort --------------- //
      const uniqList = uniqWith(dimensions, isEqual);
      const orderList = orderBy(uniqList, ['width', 'height']);
      products.dimensions = orderList;
    }

    if (products) {
      dispatch({
        type: 'PRODUCTS_LOADED',
        products,
      });
    }
    return products;
  };
}

/**
 * load products with pagination
 * @returns {Function}
 */
export function loadProductsWithPagination({ limit, page }) {
  return async (dispatch) => {
    const products = await callGetProducts({ limit, page });

    dispatch({
      type: 'PRODUCTS_LOADED',
      products: products.results,
    });

    return {
      data: products.results,
      totalCount: products.count,
    };
  };
}

/**
 * onEnter products
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export function onEnterProducts(store) {
  return onEnter({
    store,
    actionThunk: () => {
      return async (dispatch, getState) => {
        const products = await loadProductsThunk({ limit: 500 })(dispatch, getState);
        if (!products) {
          goToDashboard();
        }
        await loadTemplatesThunk()(dispatch, getState);
      };
    },
  });
}

/**
 * get templates count
 * @param templates
 * @param productId
 * @returns {number}
 */
export function getTemplatesCountForSelectedProduct(templates, productId) {
  if (!templates || !productId) {
    return 0;
  }
  const templateIdsWithProduct = [];

  templates
    .filter((template) => template.has('products'))
    .forEach((template) => {
      const index = template.get('products').findIndex((product) => product.id === productId);
      if (!templateIdsWithProduct.includes(template.id) && index >= 0) {
        templateIdsWithProduct.push(template.id);
      }
    });
  return templateIdsWithProduct.length;
}

/**
 * get templates count
 * @param templates
 * @param productId
 * @returns {ids[]}
 */
export function getTemplatesForSelectedProduct(templates, productId) {
  if (!templates || !productId) {
    return [];
  }
  const templateIdsWithProduct = [];

  templates
    .filter((template) => template.has('products'))
    .forEach((template) => {
      const index = template.get('products').findIndex((product) => product.id === productId);
      if (!templateIdsWithProduct.includes(template.id) && index >= 0) {
        templateIdsWithProduct.push(template);
      }
    });
  return templateIdsWithProduct;
}

/**
 * get templates listed by theme
 * @param templates
 * @returns {array}
 */
export const groupTemplatesByTheme = (templates) => {
  if (!templates) {
    return [];
  }

  const themes = [];
  for (const template of templates) {
    const themes = template?.get('themes');

    themes &&
      themes.map((theme) => {
        themes.push({
          theme: theme.get('name'),
          templates: theme.get('templates').filter((t) => t.id === template.id),
        });
        return theme;
      });
  }

  /** list of templates without themes */
  themes.push({
    theme: 'Sans Thème',
    templates: templates.filter((template) => !template.has('themes')),
  });
  const newThemes = themes.filter((d) => d.templates.length > 0);

  const formattedThemes = [];
  newThemes.forEach((d) => {
    const newTemplates = [];
    groupBy(newThemes, 'theme')[d.theme].map((t) => newTemplates.push(...t));
    formattedThemes.push({
      theme: d.theme,
      templates: newTemplates,
    });
  });
  const groupByTheme = [...new Map(formattedThemes.map((item) => [item.theme, item])).values()];

  return groupByTheme;
};

/**
 * update templates products
 * @param template
 * @param values
 * @param [withLayers]
 * @returns {*}
 */
export function updateTemplatesForProduct(product, templatesValues) {
  const currentTemplates = product?.templates;
  const newProduct = { id: product?.id, name: product?.name };
  if (!product) return null;

  return actionWithLoader(async () => {
    await saveProductForTemplates(currentTemplates, product?.id);
    await saveProductForTemplates(templatesValues, product?.id, newProduct);
  });
}

//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//
/**
 * show product
 * @param productId
 * @param fromNewTab
 */
export function showProduct(productId, fromNewTab = false) {
  if (fromNewTab) {
    const url = getPhotoAppURL() + '/product-' + productId;
    window.open(url);
    return;
  }
  return showParseObj('product', productId);
}

export function showProducts() {
  return push('/products');
}
