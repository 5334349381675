import React, { useEffect, useState } from 'react';

import { AppBar, Box, Container, Grid, Paper, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import CategoryIcon from '@material-ui/icons/Category';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';

import { countBackgrounds } from '../../actions/backgrounds';
import { countMontages } from '../../actions/montages';
import { countOrders } from '../../actions/orders';
import { countOverlays } from '../../actions/overlays';
import { countTemplates } from '../../actions/templates';
import { push } from '../../actions/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
    width: '100%',
  },
  appBar: {
    marginBottom: theme.spacing(4),
    backgroundColor: '#e0f0fb',
    boxShadow: '0 0px 1px rgba(0, 0, 0, 0.2)',
  },
  title: {
    flexGrow: 1,
    fontWeight: 500,
  },
  paper: {
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    transition: 'all 0.3s ease',
    background: 'white',
    border: '1px solid #f1f1f1',
    borderRadius: theme.spacing(1),
    gap: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 20px rgba(0,0,0,0.12)',
    },
  },
  icon: {
    width: 40,
    height: 40,
    marginBottom: theme.spacing(2),
    color: '#2c7be5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  stat: {
    fontSize: '1.375rem',
    fontWeight: '800',
    marginBottom: theme.spacing(1),
    color: '#555',
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    fontWeight: 500,
  },
  container: {
    marginTop: theme.spacing(4),
  },
  typography: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 1,
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const [backgroundsCount, setBackgroundsCount] = useState(0);
  const [overlaysCount, setOverlaysCount] = useState(0);
  const [templatesCount, setTemplatesCount] = useState(0);
  const [montagesCount, setMontagesCount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);

  useEffect(() => {
    (async () => {
      const [backgrounds, overlays, templates, montages, orders] = await Promise.all([
        countBackgrounds(),
        countOverlays(),
        countTemplates(),
        countMontages(),
        countOrders(),
      ]);
      setBackgroundsCount(backgrounds);
      setOverlaysCount(overlays);
      setTemplatesCount(templates);
      setMontagesCount(montages);
      setOrdersCount(orders);
    })();
  }, []);

  const stats = [
    { icon: ViewQuiltIcon, label: 'Templates', value: templatesCount, path: '/templates' },
    { icon: PhotoLibraryIcon, label: 'Fonds', value: backgroundsCount, path: '/backgrounds' },
    { icon: CategoryIcon, label: 'Overlays', value: overlaysCount, path: '/overlays' },
    { icon: AspectRatioIcon, label: 'Montages', value: montagesCount, path: '/montages' },
    { icon: ShoppingBasketIcon, label: 'Commandes', value: ordersCount, path: '/orders' },
  ];

  return (
    <Box className={classes.root}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <Typography variant="h5" className={classes.title}>
            Tableau de Bord
          </Typography>
        </Toolbar>
      </AppBar>

      <Container className={classes.container}>
        <Grid container spacing={4}>
          {stats.map((stat, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Paper className={classes.paper} elevation={0} onClick={() => push(stat.path)}>
                <div style={{ width: 60, height: 60, borderRadius: '100%', background: '#e0f0fb', position: 'relative' }}>
                  <stat.icon className={classes.icon} />
                </div>
                <div className={classes.typography}>
                  <Typography variant="h3" className={classes.stat}>
                    {stat.value.toLocaleString()}
                  </Typography>
                  <Typography variant="h6" className={classes.label}>
                    {stat.label}
                  </Typography>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;
