import axios from 'axios';
import Parse from 'parse';

import { getValues, setValues } from '../parseUtils';
import { getDownloadServerUrl, showError } from './app';
import { actionWithLoader, getPhotoAppURL, onEnter, push, showParseObj } from './utils';

import { getMontage, getMontages } from '../reducers/montages';
import { formatSavedParseObjSelectOption } from '../utils';
import { goToDashboard } from './dashboard';

//--------------------------------------------------------//
//------------------ Parse <=> Object --------------------//
//--------------------------------------------------------//
const Montage = Parse.Object.extend('Montage');

const MONTAGE_PROPERTIES = new Set(['template']);

/**
 * get values for current montage
 * @param montage
 * @returns {Object}
 */
export function getMontageValues(montage) {
  return getValues(montage, MONTAGE_PROPERTIES);
}

/**
 * set montage values
 * @param montage
 * @param values
 */
export function setMontageValues(montage, values) {
  setValues(montage, values, MONTAGE_PROPERTIES);
}

/**
 * get montage by id
 * @param montageId
 * @returns {Object}
 */
export const queryMontageById = async (montageId) => {
  const montage = await new Parse.Query('Montage').include('template').include('user').equalTo('objectId', montageId).first();

  return montage;
};

/**
 * get montage by id
 * @param montageIds
 * @returns {Object}
 */
export const queryMontagesById = async (montageIds) => {
  const montage = await new Parse.Query('Montage').include('template').containedIn('objectId', montageIds).find();
  return montage;
};

//--------------------------------------------------------//
//--------------------- CRUD actions ---------------------//
//--------------------------------------------------------//
/**
 * create new montage
 * @param values
 * @returns {*}
 */
export const createMontage = (values) => {
  return actionWithLoader(async (dispatch, getState) => {
    const montages = getMontages(getState());

    const montage = new Montage();
    await setMontageValues(montage, values);

    await montage.save();

    dispatch({
      type: 'MONTAGE_LOADED',
      montage,
    });
    dispatch({
      type: 'MONTAGES_UPDATED',
      montages: [montage, ...montages],
    });
  });
};

/**
 * update current montage
 * @param montage
 * @param values
 * @returns {*}
 */
export function updateMontage(montage, values) {
  const newValues = formatSavedParseObjSelectOption(values, 'template');

  return actionWithLoader(async (dispatch, getState) => {
    setMontageValues(montage, newValues);
    return await updateMontageThunk(montage)(dispatch, getState);
  });
}

/**
 * delete current montage
 * @param montage
 * @returns {*}
 */
export function deleteMontage(montage) {
  return actionWithLoader(async (dispatch, getState) => {
    const montages = getMontages(getState());
    const newMontages = montages.filter((m) => m !== montage);
    montage.set('deleted', true);

    const deletedMontage = await montage.save();

    dispatch({
      type: 'MONTAGES_UPDATED', // used in montages list
      montages: newMontages,
    });

    return deletedMontage;
  });
}

/**
 * saves and updates montage
 * @param {Object} montage
 */
export function updateMontageThunk(montage) {
  return async (dispatch) => {
    const newMontage = await montage.save();

    dispatch({
      type: 'MONTAGE_UPDATED',
      montage,
    });

    return newMontage;
  };
}

/**
 * load all templates
 * @returns {Function}
 */
export function loadMontagesThunk() {
  return async (dispatch) => {
    const montages = await new Parse.Query('Montage').include('template').notEqualTo('deleted', true).limit(1000).find();

    if (montages && Array.isArray(montages)) {
      dispatch({
        type: 'MONTAGES_LOADED',
        montages,
      });
    }

    return montages;
  };
}

export const countMontages = async () => {
  const query = new Parse.Query(Montage).notEqualTo('deleted', true);
  return await query.count();
};

/**
 * onEnter montages
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export function onEnterMontages(store) {
  return onEnter({
    store,
    actionThunk: () => {
      return async (dispatch, getState) => {
        const montages = await loadMontagesThunk()(dispatch, getState);

        if (!montages) {
          goToDashboard();
        }
      };
    },
  });
}

/**
 * generate and save pdf to gcs
 * @param montageId
 * @returns {*}
 */
export function saveMontagePdf(montageId) {
  return actionWithLoader(async () => {
    return await Parse.Cloud.run('saveMontagePdf', { montageId });
  });
}

//--------------------------------------------------------//
//------------------ loading montage --------------------//
//--------------------------------------------------------//
/**
 * onEnter montage preview or edit page
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export function onEnterMontage(store) {
  return onEnter({
    store,
    actionThunk: (params) => {
      return async (dispatch, getState) => {
        const montageId = params.montageId;
        const montage = await loadMontageThunk(montageId)(dispatch, getState);

        if (!montage) {
          // montage not found
          goToDashboard();
        }
      };
    },
  });
}

/**
 * load template into redux
 * @param templateId
 * @returns {function(*, *): Promise<*>}
 */
export function loadMontageThunk(montageId) {
  return async (dispatch, getState) => {
    const currentMontage = getMontage(getState());
    if (!currentMontage || currentMontage.id !== montageId) {
      const montage = await queryMontageById(montageId);

      dispatch({
        type: 'MONTAGE_LOADED',
        montage,
      });

      return montage;
    }

    return currentMontage;
  };
}

/**
 * download montage pdf
 * @param data
 * @returns {*}
 */
export function downloadMontagePdf({ orderId, productId, name }) {
  return actionWithLoader(async (dispatch) => {
    const url = getDownloadServerUrl() + `/orderAsset/?order=${orderId}&item=${productId}`;
    const fileName = `${name}-${orderId}.pdf`;
    await downloadPdf(url, fileName)(dispatch);
  });
}

/**
 * download montage pdf
 * @param data
 * @returns {*}
 */
export async function getOrderMontages(orderId) {
  const montages = await Parse.Cloud.run('getOrderMontages', { orderId });
  return montages;
}

/**
 * download gift message
 * @returns {*}
 */
export function downloadGiftMessagePdf() {
  return actionWithLoader(async (dispatch) => {
    const url = getDownloadServerUrl() + `/downloadGiftMessagePdf`;
    const fileName = `Gift_message.pdf`;
    await downloadPdf(url, fileName)(dispatch);
  });
}

const downloadPdf = (url, fileName) => {
  return async (dispatch) => {
    const currentUser = Parse.User.current();
    const sessionToken = currentUser.getSessionToken();
    const result = await axios.get(url, {
      responseType: 'arraybuffer',
      headers: { 'X-Parse-Session-Token': sessionToken },
    });

    if (result.status === 200) {
      const pdf = window.URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = pdf;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else if (result.status === 201) {
      // it return a buffer, so we only get the code through status code 2xx, not 400 or a json response
      dispatch(showError('Error while downloading pdf'));
    }
  };
};

/**
 * use signed url in montage preview
 * @param imageId
 */
export function getSignedUrlMontagePreview(montage) {
  return async () => {
    const signedUrl = await Parse.Cloud.run('getMontagePreviewSignedUrl', {
      montage,
    });
    return signedUrl;
  };
}

/**
 * refresh image url
 * @param montageId
 * @returns {function(*=, *=): Promise<void>}
 */
export function refreshImages(montageId) {
  return actionWithLoader(async () => {
    const imageUrl = await Parse.Cloud.run('refreshImages', { montageId });
    return imageUrl;
  });
}

/**
 * load montages with pagination
 * @returns {Function}
 */
export function loadMontagesPerPage(limit = 10, skip = 0, filter = '') {
  return async () => {
    let montages = new Parse.Query('Montage')
      .include('template')
      .notEqualTo('deleted', true)
      .limit(limit)
      .skip(skip)
      .descending('updatedAt')
      .withCount(true);

    if (filter !== '') {
      //----------------------------------------------------------//
      //----------- create query for template and montage id------//
      //----------------------------------------------------------//
      const templateQuery = new Parse.Query('Template').contains('name', filter);

      const searchTemplateQuery = new Parse.Query('Montage').matchesQuery('template', templateQuery);

      const searchObjectIdQuery = new Parse.Query('Montage').equalTo('objectId', filter);

      // new query with filter
      montages = Parse.Query.or(searchObjectIdQuery, searchTemplateQuery)
        .include('template')
        .notEqualTo('deleted', true)
        .limit(limit)
        .skip(skip)
        .descending('updatedAt')
        .withCount(true);
    }

    // execute query
    const result = await montages.find();

    return {
      data: result.results,
      totalCount: result.count,
    };
  };
}

//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//
/**
 * show montage
 * @param montageId
 * @param fromNewTab
 */
export function showMontage(montageId, fromNewTab = false) {
  if (fromNewTab) {
    const url = getPhotoAppURL() + '/montage-' + montageId;
    window.open(url);
    return;
  }
  return showParseObj('montage', montageId);
}

export function showMontageCreation() {
  return push('/montageCreation');
}

export function showMontages() {
  return push('/montages');
}
export function showMontageEdit(montageId) {
  return push('/montageEdit-' + montageId);
}
