import { Layer, Rect, Stage } from "react-konva";
import { addMarginToCanvas } from "../../utils";
import { useMemo } from "react";
import useImage from "use-image";
import { flattenLayers, resizeLayers } from "../templateEditor/editor/Editor";
import LayerPreviewer from "../templateEditor/editor/LayerPreviewer";

const reccursiveHaveBackgroundTypeLayer = (layers) => {
    for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === 'background') {
            return true;
        }
        if (layers[i].type === 'mask') {
            if (reccursiveHaveBackgroundTypeLayer(layers[i].layers)) {
                return true;
            }
        }
    }
    return false;
}

const Template = ({ template, className = "" }) => {
    const widthInPx = template.getWidthInPx();
    const heightInPx = template.getHeightInPx();
    const layers = template.get('layers');
    const [transparent] = useImage(`${process.env.PUBLIC_URL}/transparent.png`, 'anonymous');
    const withBackground = reccursiveHaveBackgroundTypeLayer(layers);
    const size = useMemo(
        () => {
            const zoomValue = 1
            return {
                width: widthInPx * zoomValue,
                height: heightInPx * zoomValue,
                ratio: zoomValue
            }
        },
        [heightInPx, widthInPx]
    );
    return <Stage
        width={size.width}
        height={size.height}
        className={className}
    >
        <Layer
            {...addMarginToCanvas(size)}
        >
            <Rect
                width={size.width}
                height={size.height}
                fillPatternImage={transparent}
            />
        </Layer>
        {
            resizeLayers(flattenLayers(layers, template.id).filter(({ hide }) => !hide), size.ratio).map((layer) => {
                return <LayerPreviewer
                    template={template}
                    key={layer.id}
                    layer={{
                        ...layer,
                        ...layer.type === 'userImage' ? { withBackground } : {}
                    }}
                />
            })
        }
    </Stage>;
};

export default Template;