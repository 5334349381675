import { Column } from 'devextreme-react/data-grid';
import CustomCell from './devExpressTable/CustomCell';
import CustomDataGrid from './devExpressTable/CustomDataGrid';
import CustomImageCell from './devExpressTable/CustomImageCell';
import TableButtonsAction from './devExpressTable/TableButtonsAction';

const columns = ['objectId', 'image', 'name', 'description'];

const OverlayDataGrid = ({ onFormatData, onEdit, onDelete, onChange, onMove, FilterText }) => {
  const handleEdit = (id) => {
    onEdit(id);
  };
  const handleDelete = (id) => {
    onDelete(id);
  };
  const handleMove = (id, up) => {
    onMove(id, up);
  };
  const handleOptionChange = (e) => {
    onChange(e);
  };

  return (
    <CustomDataGrid
      actionColumnWidth={150}
      reloadDataPagination={onFormatData}
      showColumnLines={false}
      defaultText={FilterText}
      actionRender={(value) => (
        <TableButtonsAction
          onEdit={() => handleEdit(value.data.objectId)}
          onDelete={() => handleDelete(value.data.objectId)}
          onMove={(up) => handleMove(value.data.objectId, up)}
          label={value.data.name}
        />
      )}
      onOptionChanged={handleOptionChange}
    >
      <Column dataField={columns[1]} caption="Image" alignment="center" cellRender={({ data }) => <CustomImageCell value={data.image} />} />
      <Column
        dataField={columns[2]}
        caption="Name"
        alignment="center"
        cellRender={({ data }) => <CustomCell value={data.name} alignment="center" />}
      />
      <Column
        dataField={columns[3]}
        caption="Description"
        alignment="center"
        cellRender={({ data }) => <CustomCell value={data.description} alignment="center" />}
      />
    </CustomDataGrid>
  );
};

export default OverlayDataGrid;
