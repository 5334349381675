import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { queryMontageById } from '../../actions/montages';

const useStyles = makeStyles({
  clientCard: {
    padding: '0.5rem',
    marginBottom: '1rem',
    '& h3': {
      fontWeight: 'bold',
    },
    '& p': {
      margin: '0.5rem 0',
    },
  },
});

const OrderClient = ({ order }) => {
  const classes = useStyles();
  const [client, setClient] = useState(null);

  useEffect(() => {
    (async () => {
      const montage = order?.metadata?.montages[0];
      if (!montage) return;
      const dataMontage = await queryMontageById(montage);
      if (!dataMontage) return;
      const clientMontage = dataMontage.get('user');
      if (clientMontage) {
        setClient(clientMontage.toJSON());
      }
    })();
  }, [order]);

  return (
    <div className={classes.clientCard}>
      <h3>Client</h3>
      <p>Email : {client?.username ?? ''}</p>
      <p>Nom : {order?.billing.name}</p>
      <p>Téléphone : {order?.billing.phone}</p>
      <p>Adresse : {order?.billing.address1}</p>
      <p>Ville : {order?.billing.state}</p>
      <p>Code postal : {order?.billing.zip}</p>
    </div>
  );
};

export default OrderClient;
