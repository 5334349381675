import Sortable from 'devextreme-react/sortable';
import TabPanel from 'devextreme-react/tab-panel';

const CustomTabPanel = ({
  tabs,
  selectedItem,
  onSelectionChanged,
  onTabDragStart,
  onTabDrop,
  children,
  renderTitle,
  style = {}
}) => {
  // const tabsWithAll = [
  //   { id: 'all', title: 'Tous' },
  //   ...tabs];
  const tabsWithAll = tabs;
  return (
    <Sortable
      filter=".dx-tab"
      data={tabsWithAll}
      itemOrientation="horizontal"
      dragDirection="horizontal"
      onDragStart={onTabDragStart}
      onReorder={onTabDrop}
    >
      <TabPanel
        style={style}
        dataSource={tabsWithAll}
        itemTitleRender={renderTitle}
        deferRendering={true}
        showNavButtons={true}
        selectedItem={selectedItem}
        repaintChangesOnly={true}
        onSelectionChanged={onSelectionChanged}
        itemComponent={(props) => (
          <div key={props.data.id} >
            {children}
          </div>
        )}
      />
    </Sortable>
  );
};

export default CustomTabPanel;
