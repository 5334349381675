import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Menu from './menu/Menu';
import { getCurrentUser, getError, getLoading, getMessage, getMessageVariant, getTitle } from '../reducers/app';
import SnackBar from '../components/SnackBar';

export const drawerWidthOpen = 240;
export const drawerWidthClosed = 70;

const useStyles = makeStyles((theme) => ({
  main: {
    composes: 'flexRow',
  },
  children: {
    composes: 'flexCenter flex1',
    padding: 12,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer * 10,
    color: theme.color.white,
  },
  menuButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    composes: 'flexRow center flexCenter',
    minHeight: '100vh',
    backgroundColor: '#edf2f9',
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const Root = (props) => {
  // state
  const [backdropOpened, setBackdropOpened] = useState(false);

  // props
  const { children } = props;

  // styles
  const classes = useStyles(props);

  // dispatch
  const dispatch = useDispatch();

  // selectors
  const title = useSelector(getTitle);
  const message = useSelector(getMessage);
  const error = useSelector(getError);
  const variant = useSelector(getMessageVariant);
  const loading = useSelector(getLoading);
  const user = useSelector(getCurrentUser);

  //-------------------------------------//
  //------------ backdrop ---------------//
  //-------------------------------------//

  const _handleCloseBackdrop = () => {
    setBackdropOpened(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setBackdropOpened(loading);
    }, 200);

    return () => {
      setBackdropOpened(false);
    };
  }, [loading]);

  const pathName = window.location.pathname;

  return (
    <Fragment>
      <Helmet>
        <title>{title || 'Yourz'}</title>
      </Helmet>
      {/* <AppHeader user={user} logout={_logout} onClickUserButton={_handleChangeMenu} /> */}

      <main className={classes.main}>
        {/* ------------ Menu ------------ */}
        {user && <Menu pathName={pathName} />}

        {/* ------------ Main Content ------------ */}
        <div className={classes.content}>{children}</div>
      </main>

      <SnackBar />

      <Backdrop className={classes.backdrop} open={backdropOpened} onClick={_handleCloseBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};

export default Root;
