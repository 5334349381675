import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Column } from 'devextreme-react/data-grid';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import CustomCell from '../../components/devExpressTable/CustomCell';
import ModalDialog from '../../components/ModalDialog';
import { getOrderUnitCurrency, toMoment } from '../../utils';
import FilterForm from './FilterForm';
import ModalProductTable from './ModalProductTable';

import { downloadGiftMessagePdf } from '../../actions/montages';
import { loadOrdersWithPagination } from '../../actions/orders';
import CustomTableGrid from '../../components/devExpressTable/CustomTableGrid';
import OrderClient from './OrdersClient';

const useStyles = makeStyles({
  root: {},
  cell: {
    composes: 'flexRow stretch center',
    height: 50,
  },
  right: {
    composes: 'justifyEnd',
  },
  imageContainer: {
    width: 60,
    height: 60,
  },
  image: {
    width: '100%',
  },
  button: {
    color: '#fff',
  },
  secondButton: {
    marginLeft: 3,
  },
});

const columns = ['number', 'paid', 'createdAt', 'nb_produit', 'total', 'client', 'data'];

const OrdersTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const currentUser = useSelector(getCurrentUser);

  // states
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [pagination, setPagination] = useState({ filterId: '', isPaid: false });
  const [isOpen, setIsOpen] = useState(true);
  const refRowsData = useRef([]);
  const [reloadData, setReloadData] = useState(false);

  // row click
  const onRowClick = ({ data }) => {
    const rowOrder = refRowsData.current.find((item) => item.number === data.number);
    setSelectedOrder(rowOrder);
    setIsOpen(true);
  };

  // close dialog
  const handleCloseDialog = () => {
    setSelectedOrder(null);
  };

  // filter by paid
  const filterByPaid = (value) => {
    setPagination({ ...pagination, isPaid: value });
    setReloadData(!reloadData);
  };

  // filter by id
  const filterByNumber = (value) => {
    setPagination({ ...pagination, filterId: value });
    setReloadData(!reloadData);
  };

  // send selected order to Switch
  // const _sendOrderToSwitch = () => {
  //   if (!selectedOrder?.number) return;
  //   dispatch(sendOrderToSwitch(selectedOrder.number));
  // };

  // button to send to shipping server
  // const _sendOrderToShipping = () => {
  //   if (!selectedOrder?.number) return;
  //   dispatch(sendOrderToShipping(selectedOrder.number));
  // };

  // button to download json
  // const _downloadOrderJson = () => {
  //   if (!selectedOrder) return null;
  //   const data = {
  //     id: selectedOrder.number,
  //   };

  //   downloadFile({ fileName: 'order.json', data, currentUser });
  // };

  // button to download gift message pdf
  const onClickBtnGiftMessage = () => {
    dispatch(downloadGiftMessagePdf());
  };

  const loadOrdersData = async (loadOptions) => {
    const dataRows = await dispatch(loadOrdersWithPagination({
      limit: loadOptions.take,
      page: loadOptions.skip / loadOptions.take,
      ...process.env.REACT_APP_CLIENT_URL ? { clientUrl: process.env.REACT_APP_CLIENT_URL } : {}, // for local dev
      ...pagination
    }));
    console.log('loadOrdersWithPagination', loadOptions, pagination);
    const data = dataRows.data.map((order) => ({
      [columns[0]]: order.number,
      [columns[1]]: order.paid ? (order.delivered ? 'Payé/Livré' : 'Payé') : 'En cours',
      [columns[2]]: order.date_created,
      [columns[3]]: (order.items || []).reduce((acc, item) => acc + item.quantity, 0),
      [columns[4]]: order.grand_total + ' ' + getOrderUnitCurrency(order.currency),
      [columns[5]]: order.billing ? order.billing.first_name + ' ' + order.billing.last_name : '',
    }));
    refRowsData.current = dataRows.data;

    return {
      data: data,
      totalCount: dataRows.totalCount,
    };
  };

  // refresh image order
  // const _refreshImagePdf = async () => {
  //   setIsOpen(false);
  //   await dispatch(refreshPdf(selectedOrder.number));
  // };

  // modal header action buttons
  const headerActionButtons = (
    <Box display="flex">
      {selectedOrder?.gift_message && (
        <Button variant="contained" color="primary" startIcon={<GetAppIcon />} onClick={onClickBtnGiftMessage} className={classes.button}>
          Gift
        </Button>
      )}
    </Box>
  );

  return (
    <div className={classes.root}>
      <Box style={{ marginBottom: 10 }}>
        <FilterForm filterByPaid={filterByPaid} filterByNumber={filterByNumber} />
      </Box>
      {/* ----------------- Order Table ----------------- */}
      <CustomTableGrid
        reloaded={reloadData}
        // dataSource={filteredDataSource || dataSource}
        reloadDataPagination={(loadOptions) => loadOrdersData(loadOptions)}
        showColumnLines={false}
        invisibleInputSearch
        onRowClick={onRowClick}
      // actionRender={(value) => <TableButtonsAction onRefresh={_refreshImagePdf} />}
      >
        <Column
          dataField={columns[0]}
          caption="Commande"
          cellRender={({ data, column }) => <CustomCell value={data.number} alignment={column.alignment} />}
        />
        <Column
          dataField={columns[5]}
          caption="Client"
          alignment="left"
          cellRender={({ data, column }) => <CustomCell value={data.client} alignment={'left'} />}
        />
        <Column dataField={columns[1]} caption="Status" cellRender={({ data }) => <CustomCell value={data.paid} />} />
        <Column
          dataField={columns[2]}
          dataType="date"
          caption="Date"
          cellRender={({ data }) => <CustomCell value={toMoment(new Date(data.createdAt)).format('YYYY-MM-DD à HH:mm')} />}
        />

        <Column
          dataField={columns[3]}
          caption="Quantité"
          alignment="right"
          cellRender={({ data }) => <CustomCell value={data.nb_produit} className={classes.right} />}
        />
        <Column
          dataField={columns[4]}
          caption="Total"
          alignment="right"
          cellRender={({ data, column }) => <CustomCell value={data.total} alignment={column.alignment} />}
        />
      </CustomTableGrid>

      {/* -------------------------- Modal Editiion ------------------------ */}
      <ModalDialog
        maxWidth="md"
        title={`Commande numeros ${selectedOrder?.number}`}
        content={
          <>
            <OrderClient order={selectedOrder} />
            <ModalProductTable data={selectedOrder} />
          </>
        }
        isVisible={!!refRowsData.current.find((order) => order.id === selectedOrder?.id) && isOpen}
        onClose={handleCloseDialog}
        labelCancel="Fermer"
        headerActionButtons={headerActionButtons}
      />
    </div>
  );
};

OrdersTable.propTypes = {
  data: PropTypes.any,
};

export default OrdersTable;
